//IkkgY2FuIGFjY2VwdCBmYWlsdXJlLCBldmVyeW9uZSBmYWlscyBhdCBzb21ldGhpbmcuIEJ1dCBJIGNhbid0IGFjY2VwdCBub3QgdHJ5aW5nLiIgLU1pY2hhZWwgSm9yZGFu

(function() {
    'use strict';

    if (!Array.prototype.indexOf) {
        Array.prototype.indexOf = function(obj, start) {
            for (var i = (start || 0), j = this.length; i < j; i++) {
                if (this[i] === obj) { return i; }
            }
            return -1;
        };
    }

    // load a locale
    numeral.register('locale', 'de', {
        delimiters: {
            thousands: '.',
            decimal: ','
        },
        abbreviations: {
            thousand: "k",
            million: "m",
            billion: "b",
            trillion: "t"
        },
        ordinal : function (number) {
            return ".";
        },
        currency: {
            symbol: '€'
        }
    });

    // switch between locales
    numeral.locale('de');

    var customTip = $('#customTip');
    var tipActive = false;

    var markerFixTimeout;

    var currentYear = new Date().getFullYear();
    var currentMode = 'export';

    // EU-Länder
    var regionEurope = [
        "GB",  // Vereinigtes Königreich
        "AT",  // Österreich
        "FR", // Frankreich
        "IT", // Italien
        "PL", // Polen
        "CZ", // Tschechien
        "NL", // Niederlande
        "ES", // Spanien
        "BE", // Belgien
        "SE", // Schweden
        "HU", // Ungarn
        "RO", // Rumänien
        "SK", // Slowakei
        "DK", // Dänemark
        "PT", // Portugal
        "FI", // Finnland
        "SI", // Slowenien
        "IE", // Irland
        "GR", // Griechenland
        "LU", // Luxemburg
        "HR", // Kroatien
        "BG", // Bulgarien
        "LT", // Litauen
        "EE", // Estland
        "LV", // Lettland
        "CY", // Zypern
        "DE" // Deutschland
        //"GL" // Grönland
    ];

    // Sonstiges Europa ohne EU/GUS
    var regionEuropeOther = [
        "CH", // Schweiz
        "TR", // Türkei
        "NO", // Norwegen
        //"LI", // Liechtenstein
        "IS" // Island
        //"SM", // San Marino
        //"AD", // Andorra
        //"FO", // Färöer
        //"GI", // Gibraltar
        //"VA" // Vatikanstadt
    ];

    // Süd-Osteuropa / GUS
    var regionEuropeSEGUS = [
        "RU", // Russland
        "UA", // Ukraine
        "RS", // Serbien
        "BA", // Bosnien-Herzegowina
        "BY", // Weißrussland
        "MK", // Mazedonien
        "KZ", // Kasachstan
        "AZ", // Aserbaidschan
        "TM", // Turkmenistan
        "UZ", // Usbekistan
        "GE", // Georgien
        "AM", // Armenien
        "TJ", // Tadchikistan
        "KG", // Kirgistan
        "MD", // Republik Moldau
        "XK", // Kosovo
        "AL", // Albanien
        "ME" // Montenegro
    ];

     // Afrika
     var regionAfrica = [
         "ZA", // Südafrika
         "EG", // Ägypten
         "MA", // Marokko
         "DZ", // Algerien
         "TN", // Tunesien
         "NG", // Nigeria
         "GH", // Ghana
         "ET", // Äthiopien
         "KE", // Kenia
         "AO", // Angola
         //"MU", // Mauritius
         "SD", // Sudan
         "CM", // Kamerun
         "CI", // Côte d'Ivoire
         "LY", // Libysch-Arabische Dschamahirija
         "TZ", // Vereinigte Republik Tansania
         "SN", // Senegal
         "ZM", // Sambia
         "GA", // Gabun
         "UG", // Uganda
         "ML", // Mali
         "CD", // Demokratische Republik Kongo
         "ZW", // Simbabwe
         "MR", // Mauretanien
         "NA", // Namibia
         "MZ", // Mosambik
         "BW", // Botsuana
         "MG", // Madagaskar
         "CG", // Republik Kongo
         "RW", // Ruanda
         "BI", // Burundi
         "DJ", // Dschibuti
         "BF", // Burkina Faso
         "BJ", // Benin
         "TD", // Tschad
         "TG", // Togo
         "GN", // Guinea
         "LR", // Liberia
         //"XL", // Melilla
         "XC", // Ceuta
         "SZ", // Swasiland
         "SO", // Somalia
         "SS", // Südsudan
         //"SC", // Seychellen und zugehörige Gebiete
         "MW", // Malawi
         "SL", // Sierra Leone
         "GQ", // Äquatorialguinea
         "NE", // Niger
         "ER", // Eritrea
         "CF", // Zentralafrikanische Republik
         "GM", // Gambia
         //"CV", // Kap Verde
         "GW", // Guinea-Bissau
         //"KM", // Komoren
         //"SH", // St. Helena und zugehörige Gebiete
         "LS", // Lesotho
         //"ST", // São Tomé und Príncipe
         "EH", // W. Sahara
         "XS" // Somaliland
     ];

     // Nah- und Mittelost
     var regionMidEast = [
         "AE", // Ver. Arab. Emirate
         "QA", // Katar
         "SA", // Saudi-Arabien
         "IL", // Israel
         "IR", // Iran
         "KW", // Kuwait
         "OM", // Oman
         "JO", // Jordanien
         "IQ", // Irak
         "LB", // Libanon
         //"BH", // Bahrain
         "PS", // Besetzte palästinensische Gebiete
         "YE", // Jemen
         "SY" // Arabische Republik Syrien
     ];

     // Asien-Pazifik
     var regionAsiaPacific = [
         "CN", // China
         "JP", // Japan
         "KR", // Südkorea
         "AU", // Australien
         "IN", // Indien
         "TW", // Taiwan
         //"SG", // Singapur
         "MY", // Malaysia
         //"HK", // Hongkong
         "TH", // Thailand
         "VN", // Vietnam
         "ID", // Indonesien
         "PH", // Philippinen
         "PK", // Pakistan
         "NZ", // Neuseeland
         "BD", // Bangladesch
         "MM", // Myanmar
         "LK", // Sri Lanka
         "KH", // Kambodscha
         //"MO", // Macau
         "MN", // Mongolei
         "BN", // Brunei Darussalam
         //"MV", // Malediven
         "AF", // Afghanistan
         "NP", // Nepal
         "LA", // Demokratische Volksrepublik Laos
         "BT", // Bhutan
         "KP", // Demokratische Volksrepublik Korea
         "TL", // Timor-Leste (ehem. Osttimor)
         //"MP", // Nördliche Marianen
         "NC", // Neukaledonien und zugehörige Gebiete
         //"PF", // Französisch-Polynesien
         //"GU", // Guam
         "FJ", // Fidschi
         //"WS", // Samoa
         "PG", // Papua-Neuguinea
         "TF", // Französische Südgebiete
         //"FM", // Föderierte Staaten von Mikronesien
         "VU", // Vanuatu
         //"AQ", // Antarktis
         "SB" // Salomonen
         //"MH", // Marshallinseln
         //"WF", // Wallis und Futuna
         //"UM", // Kleinere Amerikanische Überseeinseln
         //"TO", // Tonga
         //"PW", // Palau
         //"TK", // Tokelau
         //"CK", // Cookinseln
         //"NU" // Niue
     ];

     // Nordamerika
     var regionNorthAmerica = [
         "US", // USA
         "CA" // Kanada
     ];

     // Mittel- und Südamerika
     var regionMidSouthAmerica = [
         "MX", // Mexiko
         "BR", // Brasilien
         "AR", // Argentinien / Argentinia
         "CL", // Chile / Chile
         "CO", // Kolumbien / Colombia
         "PE", // Peru / Peru
         "CR", // Costa Rica / Costa Rica
         "GT", // Guatemala
         "EC", // Ecuador
         "VE", // Venezuela
         "PA", // Panama (einschl. ehem. Kanalzone)
         "UY", // Uruguay
         "SV", // El Salvador
         "JM", // Jamaika
         "CU", // Kuba
         "HN", // Honduras
         "DO", // Dominikanische Republik
         "BO", // Bolivien
         "NI", // Nicaragua
         "PY", // Paraguay
         "TT", // Trinidad und Tobago
         "BS", // Bahamas
         //"BB", // Barbados
         //"CW", // Curaçao
         "HT", // Haiti
         //"KY", // Kaimaninseln
         "SR", // Suriname
         //"BM", // Bermuda
         //"KN", // St. Kitts und Nevis
         //"MF", // St. Martin (niederländischer Teil)
         //"AW", // Aruba
         //"LC", // St. Lucia
         "GY", // Guyana
         "GF", // französisch Guyana
         //"VC", // St. Vincent und die Grenadinen
         "BZ", // Belize
         //"VG", // Britische Jungferninseln
         //"VG", // Antigua und Barbuda
         //"DM", // Dominica
         //"BQ", // Bonaire, St. Eustatius  und Saba
         //"GD", // Grenada (einschl. südl. Grenadinen)
         //"BL", // St. Barthélemy
         //"PM", // St. Pierre und Miquelon
         //"VI", // Amerikanische Jungferninseln
         //"TC", // Turks- und Caicosinseln
         //"AI", // Anguilla
         "FK", // Falklandinseln
     ];

    var areas = [];
    areas[0] = regionEurope;
    areas[1] = regionEuropeOther;
    areas[2] = regionEuropeSEGUS;
    areas[3] = regionAfrica;
    areas[4] = regionMidEast;
    areas[5] = regionAsiaPacific;
    areas[6] = regionNorthAmerica;
    areas[7] = regionMidSouthAmerica;

    var countryRegions = {
        0: 'EU-Länder',
        1: 'Sonstiges Europa ohne EU/GUS',
        2: 'Süd- Osteuropa / GUS',
        3: 'Afrika',
        4: 'Nah- und Mittelost',
        5: 'Asien-Pazifik',
        6: 'Nordamerika',
        7: 'Mittel- und Südamerika'
    };

    var countryRegionChartColors = {
        0: '#B1B1B1',
        1: '#5BB247',
        2: '#EE7323',
        3: '#FEC80C',
        4: '#D21F25',
        5: '#0E4A85',
        6: '#94408E',
        7: '#149DD4'
    };

    var data = {
    /*    2012: {
            0: {  // EU-Länder
                export: 86969,
                exportChange: 0.0,
                import: 81893,
                importChange: 1.5
            },
            1: {  // Sonstiges Europa ohne EU/GUS
                export: 9475,
                exportChange: -1.6,
                import: 6542,
                importChange: -0.8
            },
            2: {  // Süd- Osteuropa / GUS
                export: 6549,
                exportChange: 11.0,
                import: 13785,
                importChange: -2.6
            },
            3: {  // Afrika
                export: 3220,
                exportChange: 10.3,
                import: 6815,
                importChange: 34.7
            },
            4: {  // Nah- und Mittelost
                export: 3657,
                exportChange: -1.0,
                import: 1241,
                importChange: 11.5
            },
            5: {  // Asien-Pazifik
                export: 30345,
                exportChange: 5.3,
                import: 25364,
                importChange: -3.5
            },
            6: {  // Nordamerika
                export: 19908,
                exportChange: 18.3,
                import: 10397,
                importChange: -1.3
            },
            7: {  // Mittel- und Südamerika
                export: 4436,
                exportChange: 10.6,
                import: 1555,
                importChange: 4.2
            }
        }, */
        2013: {
            0: {  // EU-Länder
                export: 88930,
                exportChange: 2.3,
                import: 83894,
                importChange: 3.1
            },
            1: {  // Sonstiges Europa ohne EU/GUS
                export: 9664,
                exportChange: 2.0,
                import: 6635,
                importChange: 1.2
            },
            2: {  // Süd- Osteuropa / GUS
                export: 6232,
                exportChange: -4.8,
                import: 14170,
                importChange: -2.7
            },
            3: {  // Afrika
                export: 3063,
                exportChange: -4.9,
                import: 6299,
                importChange: -9.6
            },
            4: {  // Nah- und Mittelost
                export: 3652,
                exportChange: -0.1,
                import: 1325,
                importChange: 7.1
            },
            5: {  // Asien-Pazifik
                export: 30805,
                exportChange: 1.5,
                import: 23309,
                importChange: -9.3
            },
            6: {  // Nordamerika
                export: 20641,
                exportChange: 3.7,
                import: 9633,
                importChange: -7.4
            },
            7: {  // Mittel- und Südamerika
                export: 4819,
                exportChange: 8.6,
                import: 1503,
                importChange: -3.1
            }
        },
        2014: {
            0: {  // EU-Länder
                export: 90151,
                exportChange: 2.8,
                import: 89385,
                importChange: 7.1
            },
            1: {  // Sonstiges Europa ohne EU/GUS
                export: 9529,
                exportChange: -0.7,
                import: 6550,
                importChange: -1.5
            },
            2: {  // Süd- Osteuropa / GUS
                export: 5291,
                exportChange: -14.4,
                import: 12784,
                importChange: -9.5
            },
            3: {  // Afrika
                export: 2882,
                exportChange: -3.5,
                import: 4488,
                importChange: -28.8
            },
            4: {  // Nah- und Mittelost
                export: 3686,
                exportChange: 1.6,
                import: 950,
                importChange: -28.3
            },
            5: {  // Asien-Pazifik
                export: 31636,
                exportChange: 3.3,
                import: 24518,
                importChange: 4.0
            },
            6: {  // Nordamerika
                export: 21233,
                exportChange: 3.1,
                import: 10252,
                importChange: 2.4
            },
            7: {  // Mittel- und Südamerika
                export: 4155,
                exportChange: -12.6,
                import: 1377,
                importChange: -8.4
            }
        },
        2015: {
            0: {  // EU-Länder
                export: 98605,
                exportChange: 9.8,
                import: 95990,
                importChange: 7.7
            },
            1: {  // Sonstiges Europa ohne EU/GUS
                export: 9600,
                exportChange: 1.1,
                import: 6789,
                importChange: 3.5
            },
            2: {  // Süd- Osteuropa / GUS
                export: 3861,
                exportChange: -26.8,
                import: 12120,
                importChange: -10.0
            },
            3: {  // Afrika
                export: 2925,
                exportChange: -1.7,
                import: 3182,
                importChange: -29.2
            },
            4: {  // Nah- und Mittelost
                export: 4237,
                exportChange: 13.1,
                import: 812,
                importChange: -9.1
            },
            5: {  // Asien-Pazifik
                export: 30498,
                exportChange: -3.7,
                import: 28609,
                importChange: 16.9
            },
            6: {  // Nordamerika
                export: 24436,
                exportChange: 15.2,
                import: 12463,
                importChange: 21.5
            },
            7: {  // Mittel- und Südamerika
                export: 4457,
                exportChange: 7.7,
                import: 1142,
                importChange: 14.6
            }
        }, 
        2016: {
            0: {  // EU-Länder
                export: 103491,
                exportChange: 5.0,
                import: 102020,
                importChange: 6.3
            },
            1: {  // Sonstiges Europa ohne EU/GUS
                export: 9981,
                exportChange: 4.0,
                import: 7011,
                importChange: 3.3
            },
            2: {  // Süd- Osteuropa / GUS
                export: 4211,
                exportChange: 9.1,
                import: 10906,
                importChange: -10.0
            },
            3: {  // Afrika
                export: 2606,
                exportChange: -10.9,
                import: 2422,
                importChange: -23.9
            },
            4: {  // Nah- und Mittelost
                export: 4236,
                exportChange: -0.0,
                import: 757,
                importChange: -6.8
            },
            5: {  // Asien-Pazifik
                export: 31739,
                exportChange: 4.1,
                import: 28969,
                importChange: 1.3
            },
            6: {  // Nordamerika
                export: 22265,
                exportChange: -8.9,
                import: 12067,
                importChange: -3.2
            },
            7: {  // Mittel- und Südamerika
                export: 4401,
                exportChange: -1.3,
                import: 1224,
                importChange: 7.2
            }
        },
        2017: {
            0: {  // EU-Länder
                export: 108015,
                exportChange: 4.8,
                import: 110833,
                importChange: 8.6
            },
            1: {  // Sonstiges Europa ohne EU/GUS
                export: 9942,
                exportChange: 0.0,
                import: 7573,
                importChange: 5.8
            },
            2: {  // Süd- Osteuropa / GUS
                export: 4780,
                exportChange: 17.2,
                import: 10275,
                importChange: -5.7
            },
            3: {  // Afrika
                export: 2662,
                exportChange: 4.2,
                import: 3645,
                importChange: 50.3
            },
            4: {  // Nah- und Mittelost
                export: 4165,
                exportChange: -1.4,
                import: 1253,
                importChange: 65.7
            },
            5: {  // Asien-Pazifik
                export: 34075,
                exportChange: 7.5,
                import: 32424,
                importChange: 11.0
            },
            6: {  // Nordamerika
                export: 23341,
                exportChange: 4.8,
                import: 12016,
                importChange: -0.6
            },
            7: {  // Mittel- und Südamerika
                export: 4977,
                exportChange: 13.6,
                import: 1742,
                importChange: 6.8
            }
        },
    };

    function createMap() {
        var currentYearValues = data[currentYear];

        var dataMin = 0;
        var dataMax = 0;
        for (var i = 0; i < Object.keys(currentYearValues).length; i++) {
            if (currentYearValues[i][currentMode + 'Change'] < dataMin) dataMin = currentYearValues[i][currentMode + 'Change'];
            if (currentYearValues[i][currentMode + 'Change'] > dataMax) dataMax = currentYearValues[i][currentMode + 'Change'];
        }

        var values = [];
        values[0] = Number(currentYearValues[0][currentMode + 'Change']).toFixed(1);
        values[1] = Number(currentYearValues[1][currentMode + 'Change']).toFixed(1);
        values[2] = Number(currentYearValues[2][currentMode + 'Change']).toFixed(1);
        values[3] = Number(currentYearValues[3][currentMode + 'Change']).toFixed(1);
        values[4] = Number(currentYearValues[4][currentMode + 'Change']).toFixed(1);
        values[5] = Number(currentYearValues[5][currentMode + 'Change']).toFixed(1);
        values[6] = Number(currentYearValues[6][currentMode + 'Change']).toFixed(1);
        values[7] = Number(currentYearValues[7][currentMode + 'Change']).toFixed(1);

        var overallValue = 0;
        for (var i = 0; i < Object.keys(currentYearValues).length; i++) {
            overallValue += currentYearValues[i][currentMode];
        }

        var displayValues = [];
        displayValues[0] = Number((currentYearValues[0][currentMode] * 100) / overallValue).toFixed(1);
        displayValues[1] = Number((currentYearValues[1][currentMode] * 100) / overallValue).toFixed(1);
        displayValues[2] = Number((currentYearValues[2][currentMode] * 100) / overallValue).toFixed(1);
        displayValues[3] = Number((currentYearValues[3][currentMode] * 100) / overallValue).toFixed(1);
        displayValues[4] = Number((currentYearValues[4][currentMode] * 100) / overallValue).toFixed(1);
        displayValues[5] = Number((currentYearValues[5][currentMode] * 100) / overallValue).toFixed(1);
        displayValues[6] = Number((currentYearValues[6][currentMode] * 100) / overallValue).toFixed(1);
        displayValues[7] = Number((currentYearValues[7][currentMode] * 100) / overallValue).toFixed(1);

        var markers = [
            { name: countryRegions[0], coords: [53.95608553, 9.66796875], value: values[0], displayValue: values[0] },
            { name: countryRegions[1], coords: [41.11246879, 28.95996094], value: values[1], displayValue: values[1] },
            { name: countryRegions[2], coords: [64.24459476798195, 93.1640625], value: values[2], displayValue: values[2] },
            { name: countryRegions[3], coords: [19.476950206488414, -4.5703125], value: values[3], displayValue: values[3] },
            { name: countryRegions[4], coords: [20.96143961409684, 54.140625], value: values[4], displayValue: values[4] },
            { name: countryRegions[5], coords: [36.597889133070225, 91.23046875], value: values[5], displayValue: values[5] },
            { name: countryRegions[6], coords: [55.3791104480105, -119.1796875], value: values[6], displayValue: values[6] },
            { name: countryRegions[7], coords: [-15.623036831528252, -71.71875], value: values[7], displayValue: values[7] }
        ];

        var merged = areas.reduce(function(obj, arr, index) {
            arr.forEach(function(value) {
                obj[value] = values[index];
            });
            return obj;
        }, {});

        $.fn.vectorMap('addMap', 'world_mill', mapWorldMill);

        $('#world-map').vectorMap({
            map: 'world_mill',
            backgroundColor: '#fff',
            regionsSelectable: true,
            regionsSelectableOne: false,
            markersSelectable: true,
            markersSelectableOne: true,
            zoomOnScroll: false,
            panOnDrag: false,
            zoomButtons: false,
            markers: markers.map(function(h){ return {name: h.name, latLng: h.coords}; }),
            labels: {
                markers: {
                    render: function(index) {
                        var markerStr = "";
                        markerStr += '<tspan dy="0em">' + markers[index].name + "</tspan>";
                        markerStr += '<tspan dx="1.1em" dy="1.2em" class="jvectormap-element-custom-value">' + markers[index].displayValue + "%</tspan>";

                        clearTimeout(markerFixTimeout);
                        markerFixTimeout = setTimeout(fixMarkers, 1);

                        return markerStr;
                    },
                    offsets: function(index){
                        return [-25, -20];
                    }
                }
            },
            series: {
                regions: [{
                    attribute: 'fill',
                    scale: ['#66C3E4', '#F26983'],
                    min: dataMin,
                    max: dataMax,
                    values: merged
                },
                {
                    attribute: 'stroke',
                    scale: ['#66C3E4', '#F26983'],
                    min: dataMin,
                    max: dataMax,
                    values: merged
                }],
                markers: [{
                    attribute: 'r',
                    scale: [10],
                    values: markers.reduce(function(p, c, i){ p[i] = c.value; return p; }, {})
                },
                {
                    attribute: 'fill',
                    scale: ['#0B9CC3', '#D61234'],
                    min: dataMin,
                    max: dataMax,
                    values: markers.reduce(function(p, c, i){ p[i] = c.value; return p; }, {})
                }]
            },
            regionStyle: {
                initial: {
                    'fill': 'lightgray',
                    'fill-opacity': 1,
                    'stroke-width': 2,
                    'stroke-opacity': 1
                },
                selected: {
                    'fill': null,
                    'fill-opacity': 0.6,
                    'stroke-width': 2,
                    'stroke-opacity': 1
                },
                hover: {
                    'fill-opacity': 0.6,
                    'stroke-width': 2,
                    'stroke-opacity': 1
                }
            },
            markerStyle: {
                selected: {
                    'fill': null,
                    'stroke': 'black',
                    'stroke-width': 2,
                    'stroke-opacity': 1
                }
            },
            onRegionTipShow: function (e, tip, code) {
                e.preventDefault();
            },
            onMarkerTipShow: function(e, tip, code) {
                e.preventDefault();
            },
            onMarkerOver: function (e, code) {
                if (!tipActive) hoverArea(areas[code][0]);
            },
            onMarkerOut: function (e, code) {
                if (!tipActive) clearAll();
            },
            onMarkerClick: function(e, code) {
                clearAll();
                selectArea(areas[code][0]);
                customTip.addClass("fixed");
            },
            onRegionOver: function (e, code) {
                if (!tipActive) hoverArea(code);
            },
            onRegionOut: function (e, code) {
                if (!tipActive) clearAll();
            },
            onRegionClick: function(e, code) {
                clearAll();
                selectArea(code);
                customTip.addClass("fixed");
                return false;
            },
        });
        //$('#world-map').vectorMap('get', 'mapObject').container.mousemove(function(e) {
        //    left = e.pageX + 20;
        //    top = e.pageY + 20;
        //});
    }

    function removeMap() {
        if ($("#world-map").children().length > 0) {
            var mapObj = $("#world-map").vectorMap("get", "mapObject");
            mapObj.clearSelectedRegions();
            mapObj.clearSelectedMarkers();
            customTip.removeClass("fixed");
            customTip.hide();

            tipActive = false;

            mapObj.remove();
        }
    }

    function fixMarkers() {
      $('text.jvectormap-marker').each(function() {
        var $this = $(this);
        var text = $this.text();

        if (text.indexOf('</tspan>') !== -1) {
            this.innerSVG = text;
        }
        $this.find('tspan').each(function() {
          $(this).attr('x', $this.attr('x'));
        });
      });

      $('text.jvectormap-marker').fadeIn();
    }

    function hoverArea(code) {
        var mapObj = $("#world-map").vectorMap("get", "mapObject");
        areas.forEach(function(area, index) {
            if (area.indexOf(code) > -1) {
                mapObj.clearSelectedRegions();
                mapObj.setSelectedRegions(area);
            }
        });
    }

    function selectArea(code, fixed) {
        var mapObj = $("#world-map").vectorMap("get", "mapObject");
        areas.forEach(function(area, index) {
            if (area.indexOf(code) > -1) {
                mapObj.clearSelectedRegions();
                mapObj.clearSelectedMarkers();
                mapObj.setSelectedRegions(area);
                mapObj.setSelectedMarkers(index);

                var tipName = countryRegions[index];
                var tipData = data[currentYear][index];

                var currentMarkers = mapObj.getSelectedMarkers();
                var currentMarkerColor = '#000000';
                if (currentMarkers.length) {
                    currentMarkerColor = $('circle.jvectormap-marker.jvectormap-element[data-index="'+currentMarkers[0]+'"]').attr('fill');
                }

                var overallValue = 0;
                for (var i = 0; i < Object.keys(data[currentYear]).length; i++) {
                    overallValue += data[currentYear][i][currentMode];
                }

                var overallExportValue = 0;
                for (var i = 0; i < Object.keys(data[currentYear]).length; i++) {
                    overallExportValue += data[currentYear][i]['export'];
                }

                var overallImportValue = 0;
                for (var i = 0; i < Object.keys(data[currentYear]).length; i++) {
                    overallImportValue += data[currentYear][i]['import'];
                }

                var currentMarkerValue = Number((tipData[currentMode]  * 100) / overallValue).toFixed(1);

                var circleRadius = 200, circleMargin = 0;
                var circleTop = 200, circleLeft = 0;
                if (currentMarkerValue > 10) {
                    circleRadius = 250;
                    circleMargin = 10;
                }
                if (currentMarkerValue > 25) {
                    circleRadius = 300;
                    circleMargin = 10;
                }
                if (currentMarkerValue > 35) {
                    circleRadius = 350;
                    circleMargin = 10;
                }
                if (currentMarkerValue > 45) {
                    circleRadius = 570;
                    circleMargin = 15;
                    //circleTop = -90;
                }
                circleLeft = (circleRadius * -1) + 100;
                circleTop = (420 / 2) - (circleRadius / 2);

                customTip.find('.map_overlay_title').text(tipName);
                customTip.find('.map_overlay_imexpo_sum').text(numeral(tipData.export + tipData.import).format('0,0'));
                customTip.find('.map_overlay_export').text(numeral(tipData.export).format('0,0'));
                customTip.find('.map_overlay_export_share').text(Number((tipData.export  * 100) / overallExportValue).toFixed(1));
                customTip.find('.map_overlay_import').text(numeral(tipData.import).format('0,0'));
                customTip.find('.map_overlay_import_share').text(Number((tipData.import  * 100) / overallImportValue).toFixed(1));

                customTip.find('.map_overlay_current_value').text(tipData[currentMode + 'Change'] + '%');
                customTip.find('.circle').css('background', currentMarkerColor);

                customTip.find('.circle').css('height', circleRadius + 'px');
                customTip.find('.circle').css('width', circleRadius + 'px');
                //customTip.find('.circle').css('margin-top', circleMargin + 'px');

                customTip.find('.circle').css('top', circleTop + 'px');
                customTip.find('.circle').css('left', circleLeft + 'px');

                customTip.css('margin-left', (-190 + ((circleRadius/2) - 75)) + 'px');

                customTip.fadeIn();

                customTip.find(".close").click(function() {
                    mapObj.clearSelectedRegions();
                    customTip.removeClass("fixed");
                    customTip.fadeOut();

                    tipActive = false;
                });

                $(document).click(function(e) {
                    if (($(e.target).closest("#customTip").attr("id") != "customTip") &&
                        $(e.target).closest("#world-map").attr("id") != "world-map") {
                            mapObj.clearSelectedRegions();
                            customTip.removeClass("fixed");
                            customTip.fadeOut();
    
                            tipActive = false;
                    }
                });

                tipActive = true;
                return;
            }
        });
    }

    function clearAll() {
        var mapObj = $("#world-map").vectorMap("get", "mapObject");
        mapObj.clearSelectedRegions();
        mapObj.clearSelectedMarkers();
    }

    function removeChart() {
        if ($("#chart-area").children().length > 0) {
            $('#chart-area').empty();
        }
    }

    function createCmpPreYearChart() {
        var chartData = [];
        var xMax = 0;
        for (var i = 0; i < Object.keys(countryRegions).length; i++) {
            if (Object.keys(data).length > 0) {
                var regionData = data[Object.keys(data)[Object.keys(data).length - 1]][i];
                var labelPositon = 'left';
                if (regionData.exportChange < 0) labelPositon = 'right';
                var chartDataEntry = { label: countryRegions[i],  data: [ [ regionData.exportChange, i ] ], color: countryRegionChartColors[i] , showLabels: true, labels: [ regionData.exportChange + ' %' ], labelPlacement: labelPositon, labelClass: "compare-chart-label" };
                chartData.push(chartDataEntry);

                if (Math.abs(regionData.exportChange) > xMax) xMax = Math.abs(Math.round(regionData.exportChange));
            }
        }
        xMax = xMax + 1;
        var fontSize = 16;
        var showYAxis = true;
        if ($( window ).width() <= 768) {
            fontSize = 12;
            showYAxis = false;
        }

        $.plot("#chart-area", chartData, {
			series: {
				bars: {
					show: true,
					barWidth: 0.5,
					align: "center",
                    horizontal: true,
                    dataLabels: true,
                    fill: 1.0,
				}
			},
			xaxis: {
                tickLength: 2,
                show: false,
                max: xMax,
                min: (xMax * -1)
			},
            yaxis: {
                tickLength: 2,
                show: showYAxis,
                ticks: [
                    [ 0, countryRegions[0] ],
                    [ 1, countryRegions[1] ],
                    [ 2, countryRegions[2] ],
                    [ 3, countryRegions[3] ],
                    [ 4, countryRegions[4] ],
                    [ 5, countryRegions[5] ],
                    [ 6, countryRegions[6] ],
                    [ 7, countryRegions[7] ]
                ],
                transform: function (v) { return -v; },
                inverseTransform: function (v) { return -v; },
                font: {
                    size: fontSize,
                    weight: "normal",
                    family: "'Open Sans', sans-serif",
                    color: "#000000"
                }

			},
            legend: {
                show: !showYAxis
            },
            grid: {
                hoverable: true,
                borderWidth: 0,
                markings: [
                  { color: '#B1B1B1', lineWidth: 1, xaxis: { from: 0, to: 0 } },
                ]
            }
		});
    }

    function createWaregroupChart() {
        var chartData = [
			{ label: "Fahrzeuge", data: 31.8 },
			{ label: "Elektrotechnische Erzeugnisse",  data: 17.2 },
            { label: "Maschinen",  data: 16.8 },
            { label: "Chemie, Kunststoffe, Pharmazie",  data: 9.5 },
            { label: "Ernährungs- wirtschaft",  data: 4.9 },
            { label: "Metall- und Eisenwaren",  data: 3.8 },
            { label: "Halbwaren",  data: 3.8 },
            { label: "Sonstiges",  data: 12.2 },
        ];

        var chartLabel = {
            show: true,
            formatter: function (label, series) {

                var percent = series.data.toString().replace(/^[1]+[,]/, ""); // remove "1," from number

                if ($( window ).width() <= 768) {
                    return '<div class="group-chart-label"><span class="percentage" style="color: ' + series.color + ';">' + percent + '%</span></div>';
                } else {
                    return '<div class="group-chart-label"><span class="percentage" style="color: ' + series.color + ';">' + percent + '%</span><br /><span class="text">' + label +'</span></div>';
                }
            },
        };
        var showLegend = false;
        if ($( window ).width() <= 768) {
            //chartLabel = false;
            showLegend = true;
        }

        $.plot('#chart-area', chartData, {
            series: {
                pie: {
                    innerRadius: 0.6,
                    show: true,
                    label: chartLabel,
                    offset: {
                        top: 0,
                        left: 0
                    }
                }
            },
            legend: {
                show: showLegend
            },
            grid: {
                hoverable: true
            }
        });
    }

    $('.menu-export').click(function(e) {
        clearInterval(yearInterval);
        currentYear = firstYear;

        $('nav ul li').removeClass('active');
        $('#chart-area').hide();
        $('#world-map').show();
        $('.legend').show();
        $('input[type="range"]').show();
        $('.rangeslider').show();
        $('.range-numbers').show();

        $('input[type="range"]').val(currentYear).change();

        currentMode = 'export';
        removeMap();
        createMap();

        $(this).addClass('active');
        if (currentMode == 'export') $('.current-mode').text('Ausfuhr Bayerns nach Regionen');
        else $('.current-mode').text('Einfuhr Bayerns nach Regionen');
        $('.current-year').text(currentYear);

        $('.chart-headline').hide();
        $('.page-headline').show();

        yearInterval = setInterval(function() {
            if (currentYear < lastYear) {
                removeMap();
                currentYear++;

                $('.current-year').text(currentYear);
                $('input[type="range"]').val(currentYear).change();

                createMap();
            } else {
                clearInterval(yearInterval);
            }

            $(".content-wrapper").click(function(){
                $(this).data('clicked', true);
                clearInterval(yearInterval);
                $(this).data('clicked', false);
            });
            
        }, 1500);

        e.preventDefault();
    });

    $('.menu-import').click(function(e) {
        clearInterval(yearInterval);
        currentYear = firstYear;

        $('nav ul li').removeClass('active');
        $('#chart-area').hide();
        $('#world-map').show();
        $('.legend').show();
        $('input[type="range"]').show();
        $('.rangeslider').show();
        $('.range-numbers').show();

        $('input[type="range"]').val(currentYear).change();

        currentMode = 'import';
        removeMap();
        createMap();

        $(this).addClass('active');
        if (currentMode == 'export') $('.current-mode').text('Ausfuhr Bayerns nach Regionen');
        else $('.current-mode').text('Einfuhr Bayerns nach Regionen');
        $('.current-year').text(currentYear);

        $('.chart-headline').hide();
        $('.page-headline').show();

        yearInterval = setInterval(function() {
            if (currentYear < lastYear) {
                removeMap();
                currentYear++;

                $('.current-year').text(currentYear);
                $('input[type="range"]').val(currentYear).change();

                createMap();
            } else {
                clearInterval(yearInterval);
            }

            $(".content-wrapper").click(function(){
                $(this).data('clicked', true);
                clearInterval(yearInterval);
                $(this).data('clicked', false);
            });

        }, 1500);

        e.preventDefault();
    });

    $('.menu-cmppreyear').click(function (e) {
        clearInterval(yearInterval);

        $('nav ul li').removeClass('active');

        removeMap();
        $('#world-map').hide();
        $('.legend').hide();
        $('input[type="range"]').hide();
        $('.rangeslider').hide();
        $('.range-numbers').hide();

        $('#chart-area').empty();
        $('#chart-area').show();

        createCmpPreYearChart();

        $(this).addClass('active');

        $('.current-mode').text('Veränderungen im Export');
        $('.current-year').text('2017 zu 2016');

        $('.chart-headline').hide();
        $('.page-headline').show();
        e.preventDefault();
    });

    $('.menu-waregroup').click(function (e) {
        clearInterval(yearInterval);

        $('nav ul li').removeClass('active');

        removeMap();
        $('#world-map').hide();
        $('.legend').hide();
        $('input[type="range"]').hide();
        $('.rangeslider').hide();
        $('.range-numbers').hide();

        $('#chart-area').empty();
        $('#chart-area').show();

        $('.current-mode').text('Ausfuhr Bayerns nach Warengruppen');
        $('.current-year').text('2017');

        createWaregroupChart();

        $(this).addClass('active');
        $('.page-headline').hide();
        $('.chart-headline').show();
        e.preventDefault();
    });

    var years = Object.keys(data);
    var firstYear = years[0];
    var lastYear = years[years.length-1];
    currentYear = firstYear;

    var mobile = false;
    if ($( window ).width() <= 767) mobile = true;

    var yearInterval = null;

    if (mobile) {
        setTimeout(function () {
            $('.menu-cmppreyear').trigger('click');
        }, 500);
    } else {
        $('.current-year').text(currentYear);
        if (currentMode == 'export') $('.current-mode').text('Ausfuhr Bayerns nach Regionen');
        else $('.current-mode').text('Einfuhr Bayerns nach Regionen');

        $('#chart-area').hide();
        $('.chart-headline').hide();
        createMap();

        for (var i = 0; i < years.length; i++) {
            $('.range-numbers').append('<span class="year">' + years[i] + '</span>');
        }

        $('input[type="range"]').rangeslider({
            polyfill: false,
            onSlideEnd: function(position, year) {
                removeMap();

                currentYear = year;
                $('.current-year').text(currentYear);

                createMap();
            }
        });

        $('input[type="range"]').attr({
            min: firstYear,
            max: lastYear,
            value: currentYear
        }).rangeslider('update', true);

        yearInterval = setInterval(function() {
            if (currentYear < lastYear) {
                removeMap();
                currentYear++;

                $('.current-year').text(currentYear);
                $('input[type="range"]').val(currentYear).change();

                createMap();
            } else {
                clearInterval(yearInterval);
            }

            $(".content-wrapper").click(function(){
                $(this).data('clicked', true);
                clearInterval(yearInterval);
                $(this).data('clicked', false);
            });

        }, 1500);

    }

    var rtime;
    var timeout = false;
    var delta = 200;
    $(window).resize(function() {
        rtime = new Date();
        if (timeout === false) {
            timeout = true;
            setTimeout(resizeend, delta);
        }
    });

    function resizeend() {
        if (new Date() - rtime < delta) {
            setTimeout(resizeend, delta);
        } else {
            timeout = false;
            if ($('#world-map').is(":visible")) {
                removeMap();
                createMap();
            }
            if ($('#chart-area').is(":visible")) {
                $('#chart-area').empty();

                if ($('.menu-cmppreyear').hasClass('active')) {
                    createCmpPreYearChart();
                } else {
                    createWaregroupChart();
                }
            }
        }
    }
})();
